













import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class StyleChose extends Vue {
    /** S props **/

    @Prop() value!: number | string
    @Prop({ default: 0 }) min!: number
    @Prop({ default: 40 }) max!: number
    /** E props **/

    /** S computed **/

    get size() {
        return this.value
    }

    set size(val) {
        this.$emit('input', val)
    }

    /** E computed **/
}
